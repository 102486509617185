/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a POST request to the following endpoint:"), "\n", React.createElement(_components.p, null, "POST ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/payments"
  }, "https://api.uat.payroc.com/v1/payments")), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " If you are unsure whether your account supports surcharges, email our Integration team at ", React.createElement(_components.a, {
    href: "mailto:integrationsupport@payroc.com"
  }, "integrationsupport@payroc.com"), ".")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\t\"paymentId\": \"CBA7UOVS0J\",\n\t\"processingTerminalId\": \"3204001\",\n\t\"order\": {\n\t\t\"orderId\": \"Test_005\",\n\t\t\"dateTime\": \"2023-05-24T14:48:39.548+01:00\",\n\t\t\"amount\": 4160,\n\t\t\"currency\": \"USD\",\n\t\t\"breakdown\": {\n\t\t\t\"subtotal\": 4000,\n\t\t\t\"surcharge\": {\n\t\t\t\t\"bypass\": false,\n\t\t\t\t\"amount\": 160,\n\t\t\t\t\"percentage\": 4.0\n\t\t\t}\n\t\t}\n\t},\n\t\"card\": {\n\t\t\"type\": \"Visa Credit\",\n\t\t\"entryMethod\": \"keyed\",\n\t\t\"cardNumber\": \"444433******1111\",\n\t\t\"expiryDate\": \"0334\",\n\t\t\"securityChecks\": {\n\t\t\t\"cvvResult\": \"M\",\n\t\t\t\"avsResult\": \"Y\"\n\t\t}\n\t},\n\t\"paymentResult\": {\n\t\t\"type\": \"sale\",\n\t\t\"status\": \"ready\",\n\t\t\"approvalCode\": \"OK14476\",\n\t\t\"authorizedAmount\": 4160,\n\t\t\"currency\": \"USD\",\n\t\t\"responseCode\": \"A\",\n\t\t\"responseMessage\": \"OK14476\"\n\t}\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
